@import "src/constants/common/_variables.module.scss";

.addmission-card {
  min-height: 384px;
  border-radius: 6px;

  .ant-card-head {
    color: $title;
    border-bottom: 1px solid $background-secondary;
  }

  .ant-card-body {
    padding: 20.5px 30px;
    max-height: 328px;
    overflow: auto;
  }

  .admission-card-input {
    height: 287px;
    background-color: $background-light;
    padding: 11px 14px;
    border: none;

    &.ant-input-outlined:hover,
    .ant-input-outlined:focus {
      background-color: $background-light;
    }

    &.ant-input-outlined:focus {
      box-shadow: 0 0 0 2px $primary-2;
    }

    &::placeholder {
      font-weight: 500;
      color: $body;
    }
  }
}

@media (max-width: 1440px) {
  .addmission-card {
    .ant-card-body {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
