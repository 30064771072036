@import "src/constants/common/_variables.module.scss";

.header {
  background-image: url("../../../assets/img/header-background.png");
  background-color: $primary-2;
  background-blend-mode: multiply;

  width: 100%;
  height: 104px;

  position: fixed;
  top: 0;
  left: 0;

  box-shadow: 0 0 35px 0 rgba(181, 181, 195, 0.15);
  z-index: 999;
}

.logo {
  margin-top: 26px;
  margin-left: 41px;
  cursor: pointer;
}

.userInfo {
  margin-top: 30px;
  margin-right: 30px;
  height: fit-content;
  align-items: center;
}

.avatar {
  margin-right: 5px;
}

.userName {
  color: $light;
  font-size: 16px;
  font-weight: 600;
}

.userJob {
  color: $light;
  font-size: 12px;
}

.dropdownMenu {
  cursor: pointer;
}

.dropdownIcon {
  color: white;
}

.logOutIcon {
  width: 20px;
  height: 20px;
  transform: translateY(2px);
}

.logOutText {
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-1px);
}
