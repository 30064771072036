@import "src/constants/common/_variables.module.scss";

.firstColHeader {
  background-color: $primary-3 !important;

  div {
    font-size: 14px;
    font-weight: 500 !important;
    color: $grey-1 !important;
  }
}
