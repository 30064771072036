@import "src/constants/common/_variables.module.scss";

.patientSearchTitle {
  font-size: 16px;
  font-weight: 600;
  color: $title;
  padding: 20px 30px;
}

.userNameLabel,
.sexLabel,
.ageLabel,
.roomLabel,
.departmentLabel,
.wardLabel,
.doctorLabel {
  margin-bottom: 4px;
  color: $title;
}

.sexLabel {
  margin-top: 30px;
}

.ageLabel {
  margin-top: 40px;
}

.roomLabel,
.departmentLabel,
.wardLabel,
.doctorLabel,
.nameLabel,
.startDateLabel,
.endDateLabel {
  margin-top: 29px;
}

.dateInput {
  flex: 1;
}
