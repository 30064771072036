@import "src/constants/common/_variables.module.scss";

.upload-photo-btn {
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;

  .ant-btn-icon {
    transform: translateY(3px);
  }

  span:last-child {
    transform: translateY(-1px);
  }
}

.vital-sign-upload-btn,
.ventilator-setting-upload-btn {
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;

  &:hover {
    background-color: $primary-3 !important;
    color: $primary-2 !important;
    opacity: 0.8;
  }
}

.vital-sign-upload-btn {
  .ant-btn-icon {
    transform: translate(-22px, 3px);
  }

  span:last-child {
    transform: translate(-20px);
  }
}

.ventilator-setting-upload-btn {
  .ant-btn-icon {
    transform: translate(1px, 3px);
  }

  span:last-child {
    transform: translateX(3px);
  }
}
