@import "src/constants/common/_variables.module.scss";

.medicine-modal {
    .ant-modal-body {
        padding-top: 20px;
    }

    .ant-modal-header,
    .ant-modal-footer {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 23px;
    }

    .ant-modal-header {
        border-bottom: solid 1px $background-secondary;
    }

    .ant-modal-footer {
        border-top: solid 1px $background-secondary;
        padding-top: 20px;
        padding-right: 20px;
    }

    .ant-modal-title {
        margin-bottom: 16px;
    }

    .ant-pagination-item-active {
        a {
            color: $white !important;
        }

        background: $primary-2;
    }

    .medicine-search-outlined {
        font-size: 24px;
        color: $grey-3;

    }
}

.edit-medicine-modal {

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .ant-form-item-required::before {
        display: none !important;
    }

    input[type="number"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .surfix {
        color: $grey-3;
        font-size: 14px;
        font-weight: 500;
    }
}

.form-medicine-modal {

    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-picker-outlined,
    .medicine-name-input {
        border: none !important;
        height: 42px !important;
        background: $background-light !important;
        width: 100%;
    }

    .ant-select-arrow {
        margin: 0 !important;
    }
}

.select-in-medicine-search {
    border: none;
    background-color: $background-light;
    height: 42px;
    margin-top: 11px;
}
.medicine-area-component {
    margin-top: 10px;
    .ant-select-selector {
        border: none !important;
    }
    .ant-tag {
        margin-top: 5px;
    }
}

.select-in-medicine-table {
    margin-top: 15px;

    thead {
        color: $body;
    }

    color: $grey-6;

}

.delete-medicine-modal {
    .delete-action {
        display: flex;
        padding: 30px;
        justify-content: center;

        button {
            width: 112px;
            height: 61px;
            background-color: $background-light;
            color: $grey-3;
            font-size: 14px;
            font-weight: 500;
            border: none;
        }

        button:hover {
            border: 1px solid transparent;
        }

        button:last-child {
            margin-left: 16px;
        }

        button.active {
            background-color: $primary-3;
            color: $primary-2;
        }
    }
}