@import "src/constants/common/_variables.module.scss";

.personalInfo {
  height: 100%;
  border-right: 1px solid $primary-3;
  padding-left: 16px;
  padding-right: 16px;
  background-color: $grey-9;
  min-width: 340px;
}

.icon {
  margin-right: 10px;
}

.label {
  margin-right: 2px;
}

.idValue,
.nameValue {
  width: 86px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sexValue,
.ageValue {
  max-width: 52px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1872px) {
  .label,
  .valueColumn {
    gap: 15px !important;
  }
}

@media (max-width: 1680px) {
  .label,
  .valueColumn {
    gap: 15px !important;
  }
}

@media (max-width: 1580px) {
  .label,
  .valueColumn {
    gap: 18px !important;
  }
}

@media (max-width: 1440px) {
  .idValue,
  .nameValue {
    width: 70px;
  }
}

@media (max-width: 1391px) {
  .idValue,
  .nameValue {
    width: 56px;
  }
}

@media (max-width: 1280px) {
  .label,
  .valueColumn {
    gap: 21px !important;
  }
}
