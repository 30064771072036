.progress-note-date-picker {
  width: 115px;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  transform: translate(1px, 1px);

  .ant-picker-input {
    input {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .ant-picker-input-placeholder > input {
    color: inherit;
  }
}
