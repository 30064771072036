@import "src/constants/common/_variables.module.scss";

.examResultWrapper {
  flex: 1;
}

.examinationResultPage {
  padding-top: 29px;
  padding-bottom: 65px;
}

.datePicker {
  margin-bottom: 4px;
}

.bioTable,
.urinaryTable {
  max-width: 1848px;
  margin-top: 20px;
}

.bloodCountTable {
  max-width: 1680px;
  margin-top: 16px;
}

.bloodGasTable {
  max-width: 1344px;
  margin-top: 16px;
}
.coagulationTable {
  max-width: 1512px;
  margin-top: 16px;
}
