.out-input-number {
  width: 50px;
}

.out-editable {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.in-editable {
  min-height: 10px;
}

@media (max-width: 1100px) {
  .out-input-number {
    width: 35px;
  }
}