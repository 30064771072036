@import "src/constants/common/_variables.module.scss";

.ocr-image-modal {
    width: 50%;
    min-width: 520px;

    img {
        width: 100%;
    }

    .pencil-btn {
        padding: 4px 5px;
    }

    .ant-modal-body {
        margin-top: 30px;
    }
}

.image-form-modal {
    .ant-modal-body {
        padding-top: 20px;
    }

    .ant-modal-header,
    .ant-modal-footer {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 23px;
    }

    .ant-modal-header {
        border-bottom: solid 1px $background-secondary;
    }

    .ant-modal-footer {
        border-top: solid 1px $background-secondary;
        padding-top: 20px;
        padding-right: 20px;
    }

    .ant-modal-title {
        margin-bottom: 16px;
    }

    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-picker-outlined {
        border: none !important;
        height: 42px !important;
        background: $background-light !important;
        width: 100%;
    }

    .ant-select-arrow {
        margin: 0 !important;
    }
}