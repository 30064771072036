@import "src/constants/common/_variables.module.scss";

.patient-list-button-group {
  padding-top: 16px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: $light;
  min-height: 73px;
  border-radius: 0 0 6px 6px;
}

.patient-list-search-btn {
  font-family: "Manrope", "Poppins", sans-serif;
  font-weight: 500;
  box-shadow: none;

  .ant-btn-icon {
    font-size: 22px;
    transform: translateY(3px);
  }

  span:last-child:not(.anticon) {
    transform: translateY(-1px);
  }

  &:hover {
    background-color: $background-secondary !important;
    color: $grey-2 !important;
    opacity: 0.7;
  }

  &.is-searching:hover {
    background-color: $primary-3 !important;
    color: $primary-2 !important;
    opacity: 0.7;
  }
}

.patient-list-sort-btn {
  font-family: "Manrope", "Poppins", sans-serif;
  font-weight: 500;
  box-shadow: none;

  .ant-btn-icon {
    transform: translateY(3px);
  }

  span:last-child {
    transform: translateY(-2px);
  }

  & img {
    filter: invert(28%) sepia(99%) saturate(2605%) hue-rotate(201deg)
      brightness(101%) contrast(96%);
  }

  &:hover {
    background-color: $primary-3 !important;
    color: $primary-2 !important;
    opacity: 0.7;
  }
}

.patient-list-add-btn {
  font-family: "Manrope", "Poppins", sans-serif;
  font-weight: 500;
  box-shadow: none;

  margin-top: 1px;

  .ant-btn-icon {
    font-size: 22px;
    transform: translateY(2px);
  }

  .ant-btn-icon:not(:last-child) {
    margin-inline-end: 10px !important;
  }

  span:last-child:not(.anticon) {
    transform: translateY(-2px);
  }
}

@media (max-width: 680px) {
  .patient-list-button-group {
    flex-wrap: wrap;
  }

  .right-group-btn {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

@media (max-width: 410px) {
  .right-group-btn {
    flex-wrap: wrap;
  }
}
