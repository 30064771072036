@import "src/constants/common/_variables.module.scss";

.time-series-selected-date {
  padding-left: 16px;
  padding-right: 16px;
}

.icu-custom-date-picker {
  height: 44px;
}

.time-series-bio-btn,
.time-series-blood-count-btn,
.time-series-coagulation-btn,
.time-series-blood-gas-btn,
.time-series-urinary-btn {
  &:not(.time-series-active-tab):hover {
    background-color: $background-secondary !important;
    opacity: 0.7;
  }
}

.time-series-bio-btn {
  .ant-btn-icon {
    transform: translate(-2px, 3px);
  }

  span:last-child {
    transform: translate(2px, -3px);
    font-weight: 500;
    color: $grey-2;
  }
}

.time-series-blood-count-btn,
.time-series-coagulation-btn,
.time-series-blood-gas-btn,
.time-series-urinary-btn {
  .ant-btn-icon {
    transform: translateY(3px);
  }

  span:last-child {
    transform: translateY(-3px);
    font-weight: 500;
    color: $grey-2;
  }
}

.time-series-blood-gas-btn {
  .ant-btn-icon {
    transform: translateY(2px);
  }

  span:last-child {
    transform: translateY(-2px);
    font-weight: 500;
    color: $grey-2;
  }
}

.time-series-urinary-btn {
  .ant-btn-icon {
    transform: translateY(2px);
  }

  span:last-child {
    transform: translateY(-3px);
    font-weight: 500;
    color: $grey-2;
  }
}

.time-series-active-tab {
  background-color: $primary-2;

  &:hover {
    background-color: $primary-2 !important;
  }

  span:last-child {
    color: $grey-9 !important;
  }
}
