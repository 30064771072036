@import "src/constants/common/_variables.module.scss";

.vital-sign-personal-card {
  margin-bottom: 20px;
}
.img-btn {
  cursor: pointer;
}
::-webkit-scrollbar {
  display: none;
}

#vitalSignScrollBar::-webkit-scrollbar {
  display: block;
  height: 10px;
}

.pdf-vital-sign-graph {
  visibility: hidden;
  position: absolute;
}

.pdf-vital-sign-table-section,
.pdf-ventilator-table-section,
.pdf-in-out-balance-component,
.pdf-out-table-section,
.pdf-balance-table-section {
  display: none;
}


.pdf-export-btn {
  transform: translateY(-3px);

  img {
    transform: translate(-9px, 2px);
  }

  &:hover {
    background-color: $background-secondary !important;
    opacity: 0.7;
  }
}

.vital-sign-page-pdf {
  zoom: 0.4;
  .vital-sign-page {
    padding-top: 4px;
    zoom: 1.15;
  }

  .icu-wrapper {
    margin-top: 16px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: $light;
  }

  .exam-card-personal-info-container {
    flex: 1;
  }

  .vital-sign-personal-card {
    min-height: 80px;
    margin-bottom: -30px;
    border: 1px solid #000000 !important;
  }

  .vital-sign-graph-header {
    height: 50px;
  }

  .ant-table-cell,
    table > thead > tr:not(:last-child) > th {
      border: 1px solid #000000 !important;
    }

  .pdf-graph-section-container {
    margin-bottom: 32px;
  }

  .pdf-vital-sign-table,
  .pdf-ventilator-table {
    .ant-table-measure-row {
      display: none;
    }

    td {
      padding: 8px !important;
    }
  }

  .pdf-graph-section {
    border: 1px solid #000000;
  }

  .pdf-range-col {
    border-right: 1px solid #000000;
    max-height: 584px;
  }

  .icu-header,
  .icu-footer,
  .group-btn,
  .icu-custom-date-picker,
  #vitalSignScrollBar,
  .medicine-dropdown-menu,
  .medicine-delete,
  .medicine-copy,
  .medicine-edit,
  .graph-section-container,
  .vital-sign-table-section,
  .ventilator-table-section,
  .inOutBalanceComponent {
    display: none;
  }

  .pdf-out-table-section {
    display: block;
    margin-bottom: 32px;
    margin-top: 100px;
  }

  .pdf-vital-sign-table-section,
  .pdf-in-table-section,
  .pdf-in-out-balance-component,
  .pdf-balance-table-section {
    display: block;
  }

  .pdf-ventilator-table-section {
    display: block;
    break-after: page;

    .img-btn,
    .img-btn img {
      height: 20px;
    }
  }

  .pdf-vital-sign-graph {
    visibility: visible;
    position: relative;
  }

  .pdf-vital-sign-table-section {
    .pdf-vital-sign-table th {
      background-color: #d4e9ff !important;
    }


    .img-btn,
    .img-btn img {
      height: 20px;
    }
  }

  .pdf-vital-sign-header {
    width: 300px !important;
    top: 300px !important;
  }

  .pdf-ventilator-header {
    width: 520px !important;
    top: 520px !important;
  }

  .pdf-in-table-section .ant-tree-switcher-leaf-line::before,
  .pdf-in-table-section .ant-tree-switcher-leaf-line::after {
    border-color: #000000 !important;
    border-width: 1px !important;
  }
}

@media print {
  @page {
    margin-top: 32px;
  }

  @page :first {
    margin-top: 0px;
  }
}