@import "src/constants/common/_variables.module.scss";

.patientRegisterTitle {
  font-size: 16px;
  font-weight: 600;
  color: $title;
  padding: 20px 30px;
}

.idLabel,
.nameLabel,
.birthdayLabel,
.illnessLabel,
.roomLabel,
.departmentLabel,
.wardLabel {
  margin-bottom: 4px;
  color: $title;
}

.nameLabel,
.wardLabel {
  margin-top: 30px;
}

.sexLabel {
  margin-top: 31px;
  margin-bottom: 17px;
  color: $title;
}

.birthdayLabel {
  margin-top: 41px;
}

.roomLabel,
.departmentLabel {
  margin-top: 30px;
}

.footerDivider {
  min-width: 751px;
  transform: translateX(-30px);
  margin-top: 26px;
  margin-bottom: 30px;
}
