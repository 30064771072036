@import "src/constants/common/_variables.module.scss";

.out-table {
    position: absolute;
    left: 38px;
    max-width: calc(100% - 38px);
    top: 54px;

    .out-table-col {
        position: relative;
        height: 66px;
    }

    .title {
        font-size: 14px;
        font-weight: 600;
        color: $grey-1;
        text-align: center;
    }

    thead {
        display: none;
    }

    .in-table_cell {
        color: $grey-2;
        font-size: 14px;
        font-weight: 500;
    }
    .ant-table-content {
        scrollbar-color: auto;
    }
    tr:not(:first-child) td:not(:first-child) {
        padding: 0 !important;
    }

    .medicine-component {
        display: flex;
        justify-content: space-between;

        .medicine-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2px;
            width: 100%;

            .medicine-name {
                color: $grey-1;
                background-color: $yellow-1;
                display: inline-block;
                padding: 7px;
                border-radius: 6px;
                font-size: 14px;
                border: none;
            }
            .medicine-span-name {
                visibility: hidden;
                position: absolute;
            }
            .medicine-delete {
                padding-left: 7px;
                padding-right: 7px;
            }

        }

        .medicine-action {
            .medicine-delete {
                background-color: $danger;
                padding-left: 7px;
                padding-right: 7px;
            }

            .medicine-edit {
                background-color: $primary-2;
                padding-left: 7px;
                padding-right: 7px;
                margin-left: 10px;
            }
        }
    }

    .in-total-style {
        font-size: 16px;
        font-weight: 600;
        color: $grey-1;
    }

    tbody tr:last-child {
        td:not(:first-child) {
            border: solid 1px $yellow-2;
        }

        td {
            background-color: $yellow-1;
        }

        td .in-table_cell {
            color: $grey-1;
        }

        td:last-child {
            border-bottom-right-radius: 6px;
        }
    }

}
.outTableAdd {
    button {
        width: 81px;
        height: 34px;
        background-color: #d4e9ff !important;
        color: #0577f7;
        align-items: center;
        border: none;
    }
}

.outTableSection,
.pdf-out-table-section {
    position: relative;
    background: $white;
    border-radius: 10px;
}

.outTableHeader,
.pdf-out-table-header {
    height: 33px;
    background-color: $warning;
    color: $grey-1;
    font-size: 20px;
    font-weight: 600;
    transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px 6px 0 0;
    transform-origin: 0 0;
    position: absolute;
    left: 0px;
    right: 1086px;
}