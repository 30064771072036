.patientListPage {
  padding-top: 28px;
  padding-bottom: 30px;
}

.noResultMessage {
  font-size: 20px;
  font-weight: 600;

  min-height: 543px;
  align-items: center;
  display: flex;
  justify-content: center;
}
