@import "src/constants/common/_variables.module.scss";

.paginationSection {
  background-color: $light;
  height: 80px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 16px;
  border-radius: 0 0 6px 6px;
}
