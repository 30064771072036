@import "src/constants/common/_variables.module.scss";

.graphSectionContainer {
  margin-bottom: 50px;
  margin-left: 135px;
  position: relative;
  margin-top: 67px;
}

.vitalSignHeader {
  background-color: $primary-3;
  font-size: 14px;
  font-weight: 500;
  color: $grey-1;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px 6px 0 0;
}

.vitalSignGraphHeader {
  height: 53px;
  background-color: #d4e9ff;
  color: #0577f7;
  font-size: 20px;
  font-weight: 600;
  transform: rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  transform-origin: 0 0;
  position: absolute;
  left: -61px;
  width: 584px;
  top: 584px;
}

.graphSection {
  background-color: $grey-9;
  max-height: 584px;
  max-width: 2192px;
  width: auto !important;
  .rangeTableContainer {
    padding-right: 17px;
  }
}

.rangeCol {
  width: 40px;
  border-right: 1px solid $border;
}

.vitalSignCheckbox {
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 4px;
}

.rangeValues div {
  text-align: center;
  padding: 11.5px 0;
  color: $grey-1;
}

.rangeValue {
  position: relative;

  &::before {
    content: "";
    width: 4px;
    height: 1px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0px;
    border-bottom: solid 1px;
  }

  &::after {
    content: "";
    width: 4px;
    height: 1px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0px;
    border-bottom: solid 1px;
  }
}

.graphContainer {
  width: 100%;
  flex: 1;
  overflow-x: auto;
  margin-left: -17px;
  padding-right: 12px;
  margin-top: 30px;

}

.graphContent {
  height: 100%;
  min-width: 110px;
  margin-left: 17px;
  padding-right: 15px;
  position: relative;
}

.graphContentTimeLine {
  width: calc(100% - 280px);
  position: absolute;
  color: rgb(70, 78, 95);
  margin-left: 280px;
  top: -47px;
  padding: 11px 0;
  overflow-y: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: $primary-3;
  height: 44px;
  font-weight: 600;
  div {
    display: inline-flex;
    margin-left: 18px;
    height: 22px;
    width: 1895px;
  }
  span:not(:last-child) {
    width: 80px;
    display: inline-block;
  }
}
