@import "src/constants/common/_variables.module.scss";

.treatmentInfo {
  height: 100%;
  border-right: 1px solid $primary-3;
  padding-left: 16px;
  padding-right: 16px;
  background-color: $grey-9;
}

.icon {
  margin-right: 10px;
}

.label {
  margin-right: 2px;
}

.tagList {
  margin-left: 4px;
}

.departmentIcon,
.departmentLabel {
  flex: 1;
  align-content: center;
}

.illnessValue,
.dayStayValue {
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.roomValue,
.wardValue {
  max-width: 84px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.doctorLabel,
.departmentLabel,
.doctorIcon,
.departmentIcon {
  margin-top: 1px;
}

.admissionDateIcon {
  width: 20px;
  height: 20px;
}

.dateIconSection {
  margin-right: 10px;
}

@media (min-width: 2100px) {
  .treatmentInfo {
    min-width: 1142px;
    gap: 110px !important;
  }
}

@media (max-width: 1872px) {
  .label,
  .valueColumn {
    gap: 12px !important;
  }
}

@media (max-width: 1680px) {
  .treatmentInfo {
    gap: 16px !important;
  }

  .label,
  .valueColumn {
    gap: 15px !important;
  }

  .illnessValue,
  .dayStayValue {
    width: 70px;
  }
}

@media (max-width: 1580px) {
  .label,
  .valueColumn {
    gap: 18px !important;
  }

  .doctorLabel,
  .departmentLabel {
    transform: translateY(2px);
  }
}

@media (max-width: 1391px) {
  .illnessValue,
  .dayStayValue {
    width: 50px;
  }
}

@media (max-width: 1280px) {
  .treatmentInfo {
    gap: 10px !important;
  }

  .label,
  .valueColumn {
    gap: 21px !important;
  }
}
