@import "src/constants/common/_variables.module.scss";

.footer {
  color: $body;
  font-size: 12px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 22px;

  width: 100%;
  height: 64px;
  background-color: $light;

  box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
}
