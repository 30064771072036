@import "src/constants/common/_variables.module.scss";

.examination-result-personal-info {
  min-width: 555px !important;
}

.examination-result-time-series-btn {
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;

  .ant-btn-icon {
    transform: translate(-1px, 3px);
  }

  span:last-child {
    transform: translate(1px, -1px);
  }
}

.pdf-export-btn {
  img {
    transform: translate(-9px, 2px);
  }

  &:hover {
    background-color: $background-secondary !important;
    opacity: 0.7;
  }
}

.upload-photo-btn {
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;

  .ant-btn-icon {
    transform: translate(-24px, 3px);
  }

  span:last-child {
    transform: translate(-24px, -1px);
  }

  &:hover {
    background-color: $primary-3 !important;
    color: $primary-2 !important;
    opacity: 0.8;
  }
}
