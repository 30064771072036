@import "src/constants/common/_variables.module.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Manrope", "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;

  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.bg-light {
  background-color: $background-light;
}

.border-none {
  border: none;
}

.text-light {
  color: $light;
}

.bg-primary-2 {
  background-color: $primary-2;
}

.bg-secondary {
  background-color: $background-secondary;
}

.text-grey-2 {
  color: $grey-2;
}

.bg-primary-3 {
  background-color: $primary-3;
}

.text-primary-2 {
  color: $primary-2;
}

.bg-danger {
  background-color: $danger;
}

.bg-success {
  background-color: $success;
}

.text-grey-9 {
  color: $grey-9;
}

.text-grey-3 {
  color: $grey-3;
}

.bg-grey-6 {
  background: $grey-6;
}

.text-body {
  color: $body;
}

.mt-6 {
  margin-top: 6px !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-track {
  background: $primary-3;
}

::-webkit-scrollbar-thumb {
  background: $primary-2;
  border-radius: 99px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($color: $primary-2, $alpha: 0.8);
}

.upload-img-checked-icon,
.upload-img-failed-icon {
  transform: translateY(3px);
  margin-right: 8px;
}

.upload-img-success,
.upload-img-failed,
.create-progress-success,
.create-progress-failed {
  .ant-message-notice-content {
    width: 519px;
    height: 62px;
  }

  .ant-message-custom-content {
    padding-top: 10px;
  }

  .upload-img-close-icon {
    height: 12px;
    margin-top: 5px;
    cursor: pointer;
  }
}

.upload-img-success {
  .ant-message-notice-content {
    background-color: #d1e7dd !important;
    color: #0f5132;
  }
}

.upload-img-failed {
  .ant-message-notice-content {
    background-color: #f8d7da !important;
    color: $abp;
  }
}
