@import "/src/constants/common/_variables.module.scss";

.icu-select .ant-select-selector {
  background-color: $background-light !important;
  border: none !important;
  padding-left: 14px;
}

.icu-select.ant-select-open .ant-select-selector {
  box-shadow: 0 0 0 2px $primary-2 !important;
}

.icu-select.ant-select-focused:not(.ant-select-open) .ant-select-selector {
  box-shadow: none !important;
}

.select-tag {
  color: $grey-2 !important;
  margin-inline-end: 5px;
  font-family: "Manrope", "Poppins", sans-serif;
  border-radius: 6px;

  .ant-tag-close-icon {
    color: $grey-3 !important;
  }
}

.ant-select-dropdown {
  padding: 0;

  .ant-select-item-option {
    height: 39px;
    border-radius: 0;
    align-items: center;

    font-family: "Manrope", "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: $grey-3;

    &.ant-select-item-option-disabled {
      color: rgba($color: $grey-3, $alpha: 0.25);
    }
  }

  .ant-select-item-option-selected {
    background-color: $primary-2 !important;

    .ant-select-item-option-content {
      color: $grey-9;
    }
  }

  .anticon-check {
    color: $light;
    width: 9.15px;
    height: 7.32px;
  }
}
