@import "src/constants/common/_variables.module.scss";

.icu-text-input {
  &.ant-input {
    padding: 11px 14px;
    font-family: "Manrope", "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &.ant-input-disabled:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.ant-input-outlined:hover,
.ant-input-outlined:focus {
  background-color: $background-light;
}

.ant-input-outlined:focus {
  box-shadow: 0 0 0 2px $primary-2;
}
