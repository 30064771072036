@import "src/constants/common/_variables.module.scss";

.timeBtn {
  width: 94px;
  height: 50px;
  background-color: $primary-3;
  color: $primary-2;
  border-radius: 6px;
  text-align: center;
  padding-top: 7px;
  margin-bottom: 8px;

  &:not(.active):hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.time {
  font-size: 12px;
  font-weight: 500;
}

.author {
  font-size: 10px;
  font-weight: 500;
}

.active {
  background-color: $primary-2;
  color: $grey-9;
}

.underRecordingBtn {
  transform: translateY(-3px);
  font-size: 13px;
}
