@import "src/constants/common/_variables.module.scss";

.progress-note-container {
  .ant-card-head {
    padding-left: 30px;
    padding-right: 30px;
  }

  .ant-card-body {
    padding: 30px;
    max-height: 710px;
    overflow: auto;
  }

  .progress-note-add-btn {
    padding-top: 6px;
    margin-bottom: 8px;

    .anticon-plus-circle {
      font-size: 20px;
    }
  }

  .progress-note-input {
    background-color: $background-light;
    min-height: 650px;
    padding: 11px 14px;
    border: none;

    &.ant-input-outlined:hover,
    .ant-input-outlined:focus {
      background-color: $background-light;
    }

    &.ant-input-outlined:focus {
      box-shadow: 0 0 0 2px $primary-2;
    }

    &::placeholder {
      font-weight: 500;
      color: $body;
    }
  }
}

.quill {
  width: 100%;
  min-height: 650px;

  .ql-container {
    height: calc(100% - 42px);
    background-color: $background-light;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .ql-toolbar {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

@media (max-width: 480px) {
  .progress-note-container .ant-card-body {
    padding-left: 12px;
    padding-right: 12px;
  }
}
