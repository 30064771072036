.icu-error-modal {
  .ant-modal-content {
    height: 258px;
  }

  .ant-modal-close {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-top: 8px;
  }

  .ant-modal-close-icon {
    width: 12px;
    height: 12px;
  }

  .ant-btn {
    margin-right: 6px;
    font-family: "Roboto", "Poppins", sans-serif;
  }
}
