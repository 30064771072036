.container {
  flex: 1;
}

.coverImg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .container {
    display: none;
  }
}
