@import "src/constants/common/_variables.module.scss";

.timeSeriesExamPage {
  padding-top: 29px;
  padding-bottom: 21px;
}

.timeSeriesExamTable {
  margin-top: 23px;
  max-width: 1848px;
  overflow: auto;
}

@media (max-width: 1110px) {
  .btnGroup {
    flex-direction: column;
    align-items: flex-end;
    gap: 24px !important;
  }
}

@media (max-width: 933px) {
  .examTypeButtons {
    flex-wrap: wrap;
  }
}
