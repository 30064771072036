// Base colors
$primary-2: #0577f7;
$primary-3: #d4e9ff;
$light: #ffffff;
$body: #a1a5b7;
$grey-6: #e5eaee;
$danger: #f1416c;
$success: #50cd89;
$title: #181c32;
$grey: #a7a8bb;
$abp: #cc0000;
$grey-2: #464e5f;
$grey-9: #ffffff;
$grey-3: #80808f;
$grey-5: #d6d6e0;
$grey-7: #ecf0f3;
$primary-1: #1890ff;
$grey-1: #1b283f;
$grey-8: #f3f6f9;
$hr: #00ff00;
$rr: #ffd600;
$nibp: #ff64ff;
$spo2: #0000ff;
$grey-4: #b5b5c3;
$white: #ffffff;
$warning: #ffc700;
$yellow-1: #fff4de;
$yellow-2: #f3cb75;
$pink: #ffedef;
$pink-1: #ffb4bc;
$success: #50cd89;

// Background colors
$background-light: #f5f8fa;
$background-secondary: #e4e6ef;

// Border color
$border: #f0f0f0;