@import "src/constants/common/_variables.module.scss";

.vitalSignPage {
  padding-top: 30px;
  padding-bottom: 59px;
  max-width: 2332px;
  width: 100%;
}
.vitalSignPageWrapper {
  display: flex;
  justify-content: center;
}
.vitalSignTableSection {
  margin-top: 48px;
  position: relative;
}

.datePicker {
  margin-bottom: 21px;
}

.vetilatorTableSection {
  position: relative;
  margin-top: 20px;
}

.vitalSignHeader,
.ventilatorHeader {
  height: 53px;
  background-color: $primary-3;
  color: $primary-2;
  font-size: 20px;
  font-weight: 600;
  transform: rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  transform-origin: 0 0;
  position: absolute;
  left: 246px;
}

.ventilatorHeader {
  right: 1086px;
}

.vitalSignScrollBar {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 447px;
  width: calc(100% - 488px);
  height: 20;
  overflow-y: hidden;
  div {
    display: inline-flex;
    margin-left: 18px;
    height: 22px;
    width: 1895px;
  }
}

