@import "src/constants/common/_variables.module.scss";

.vital-sign-table,
.pdf-vital-sign-table {
  position: absolute;
  left: 305px;
  max-width: calc(100% - 305px);

  .ant-table {
    scrollbar-color: inherit;
  }

  .vital-sign-table-first-col,
  .pdf-vital-sign-table-first-col {
    border-bottom: none !important;

    div {
      font-weight: 600;
    }
  }

  td {
    color: $grey-1;
  }

  .ant-table-cell {
    padding: 11.5px 16px !important;
  }
}