@import "src/constants/common/_variables.module.scss";

.in-table {
    position: absolute;
    left: 38px;
    max-width: calc(100% - 38px);

    .in-table-col {
        position: relative;
        height: 66px;

        &:has(.inbalance-in-active) {
            background: $grey-5;
        }

        .in-table-status {
            border-bottom: solid 1px #ecf0f3;
            height: 46px;
            padding: 2px;
            position: absolute;
            width: 100%;
            top: 0px;
            left: 0px;
            display: flex;
            align-items: center;

            .active {
                display: block;
                background-color: $primary-2;
                width: 100%;
                height: 30px;
                border-radius: 4px;
            }
        }
    }

    .ant-table-container {
        border-top: none !important;
        border-left: none !important;
        .ant-table-content {
            scrollbar-color: auto;
        }
    }

    .ant-table-tbody tr td {
        padding: 9px 16px;
    }

    .title {
        font-size: 14px;
        font-weight: 600;
        color: $grey-1;
        text-align: center;
    }

    thead th {
        height: 46px !important;
        padding: 0 !important
    }

    thead th:first-child {
        background-color: $white;
    }

    thead th:nth-child(2) {
        border-top-left-radius: 6px;
    }

    thead th:last-child {
        border-top-right-radius: 6px !important;
    }

    thead th:not(:first-child) {
        border: none !important;
        background-color: $primary-3 !important;
    }

    tr td:first-child {
        border-inline-start: 1px solid #f0f0f0;
    }

    tr:not(:last-child) {
        .in-table_cell {
            margin-top: 46px;
        }
    }

    .in-table_cell {
        color: $grey-2;
        font-size: 14px;
        font-weight: 500;
    }

    .medicine-cell {
        position: relative;

        .medicine-action {
            display: inline-block;
            top: 0px;
            right: 0px;
            position: absolute;

            .medicine-delete {
                background-color: $danger;
                padding-left: 7px;
                padding-right: 7px;
            }

            .medicine-edit {
                background-color: $primary-2;
                padding-left: 7px;
                padding-right: 7px;
                margin-left: 10px;
            }

            .medicine-copy {
                background-color: $success;
                padding-left: 7px;
                padding-right: 7px;
                margin-left: 10px;
            }

            .medicine-undo {
                background-color: $nibp;
                padding-left: 7px;
                padding-right: 7px;
                margin-left: 10px;
            }
        }

        .medicine-tree {
            margin-left: -16px;

            .medicine-tree-title {

                .ant-select-selector,
                .ant-select-selection-item {
                    font-size: 14px;
                    font-weight: 700;
                    color: $grey-1;
                    border: none;
                }

                .ant-select-arrow {
                    color: $grey-1;
                }

                .ant-select-selector:focus-visible,
                .ant-select-selection-item:focus-visible {
                    border: none;
                }
            }

            .ant-tree-indent,
            .ant-tree-switcher_open {
                display: none;
            }

            .ant-tree-treenode:nth-child(2) {
                .ant-tree-switcher-leaf-line::before {
                    top: 19px !important;
                    min-height: 22px !important;
                }
            }

            .ant-tree-switcher-leaf-line {
                &::after {
                    width: 7px;
                    height: 22px;
                    top: -2px;
                    border-bottom: 1px solid $grey-5;
                }

                &::before {
                    border-inline-end: 1px solid $grey-5;
                }

            }

            .ant-tree-switcher {
                line-height: 44px !important;
            }

            .ant-tree-treenode-leaf-last {
                .ant-tree-switcher-leaf-line {
                    &::before {
                        height: calc(50% - 5px) !important;
                    }
                    &::after {
                        width: 38px;
                    }
                }

                .ant-tree-node-content-wrapper {
                    margin-left: 29px !important;
                }

            }

            .ant-tree-node-content-wrapper {
                padding: 0 !important;
                width: 100%;
                &:hover {
                    background: none;
                }
            }
            .ant-tree-treenode {
                width: 100%;
            }
        }
    }

    .medicine-component {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        .medicine-info {
            display: block;
            width: 100%;

            .medicine-name {
                color: $primary-2;
                background-color: $primary-3;
                display: inline-block;
                padding: 7px;
                border-radius: 6px;
                font-size: 14px;
                word-break: break-word;
            }

            input {
                width: 58px;
                border: none;
            }

            input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type="number"] {
                -moz-appearance: textfield;
                /* Firefox */
                appearance: none;
                /* Chrome, Safari, Opera */
            }

            .medicine-edit {
                cursor: text;
            }

            .medicine-times {
                color: $grey-3;
                background-color: $background-light;
                display: inline-block;
                padding: 7px 17px;
                border-radius: 6px;
                font-size: 12px;
                margin-left: 10px;
            }

            .medicine-ml {
                color: $grey-3;
                background-color: $background-light;
                display: inline-block;
                padding: 7px;
                border-radius: 6px;
                font-size: 14px;
                margin-left: 10px;
                border: none;
                height: 34px;
                text-align: center;
                margin-top: 4px;
            }

            .unit-options {
                background-color: $background-light;
                margin-left: 10px;
                border-radius: 6px;
                color: $grey-3;
                height: 34px;
                min-width: 56px;
                margin-top: 4px;

                .ant-select-selector {
                    border: none;
                    background: unset;
                }
            }

        }
    }

    .in-total-style {
        font-size: 16px;
        font-weight: 600;
        color: $grey-1;
    }

    tbody tr:last-child {
        td:not(:first-child) {
            border: solid 1px #A6D0FF;
        }

        td {
            background-color: $primary-3;
        }

        td .in-table_cell {
            color: $primary-2;
        }

        td:last-child {
            border-bottom-right-radius: 6px;
        }

        .in-table-status {
            display: none;
        }
    }

}

.inTableSection,
.pdf-in-table-section {
    position: relative;
    background: $white;
    border-radius: 10px;
}

.intableHeader,
.pdf-in-table-header {
    height: 33px;
    background-color: $primary-2;
    color: $white;
    font-size: 20px;
    font-weight: 600;
    transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px 6px 0 0;
    transform-origin: 0 0;
    position: absolute;
    left: 0px;
    right: 1086px;
}