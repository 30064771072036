@import "src/constants/common/_variables.module.scss";

.rc-virtual-list-holder {
  overflow-x: hidden;
}

.patient-list-limit-select {
  width: 73px;
  height: 34px;
  .ant-select-selector {
    font-size: 12px;
    color: $grey-3;
    background-color: $grey-7 !important;
    box-shadow: none !important;
    border: none !important;
    padding-left: 14px;
  }

  .ant-select-suffix {
    width: 10px;
    height: 10px;
  }

  &.ant-select-open .ant-select-selector {
    box-shadow: 0 0 0 2px $primary-2 !important;
  }
}

.patient-list-pagination {
  .ant-pagination-item a {
    color: $grey-2;
  }

  .ant-pagination-item-active {
    background-color: $primary-2;

    a {
      color: $grey-9;
    }

    &:hover a {
      color: $grey-9;
    }
  }
}
