@import "src/constants/common/_variables.module.scss";

.dropdownContainer {
  width: 222px;
  height: 144px;
  background-color: $light;
  padding: 30px;
  box-shadow: 0px 0px 50px 0px #523f691a;
  border-radius: 6px;
}
