@media (max-width: 630px) {
  .patient-detail-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 375px) {
  .patient-detail-wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }
}
