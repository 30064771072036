@import "src/constants/common/_variables.module.scss";

.patient-detail-weight-input .ant-input-number,
.patient-detail-height-input .ant-input-number,
.patient-detail-bsa-input .ant-input-number {
  position: relative;

  &::after {
    position: absolute;
    bottom: 11px;
    right: 14px;
    color: $title;
  }
}

.patient-detail-weight-input .ant-input-number:after {
  content: "kg";
}

.patient-detail-height-input .ant-input-number:after {
  content: "cm";
}

.patient-detail-weight-input,
.patient-detail-height-input,
.patient-detail-bsa-input,
.patient-detail-bsa-num-input {
  width: 177px;
  height: 42px;

  .ant-input-number-input {
    padding: 11px 14px;
    font-family: "Manrope", "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-input-number-input-wrap {
    height: 100%;

    input {
      height: 100%;
    }
  }

  .patient-detail-bsa-input {
    position: relative !important;
  }

  .bsa-unit {
    position: absolute;
    transform: translate(-32px, 8px);
  }

  .ant-input-number-outlined:hover,
  .ant-input-number-outlined:focus,
  .ant-input-number-outlined:focus-within {
    background-color: $background-light;
  }

  .ant-input-number-outlined:focus,
  .ant-input-number-outlined:focus-within {
    box-shadow: 0 0 0 2px $primary-2;
  }
}

@media (max-width: 1890px) {
  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 360px !important;
  }
}

@media (max-width: 1594px) {
  .patient-treatment-form-container {
    gap: 48px !important;
  }

  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 300px !important;
  }
}

@media (max-width: 1493px) {
  .patient-treatment-form-container {
    gap: 36px !important;
  }

  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 250px !important;
  }
}

@media (max-width: 1391px) {
  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 200px !important;
  }
}

@media (max-width: 1280px) {
  .patient-treatment-form-container {
    gap: 28px !important;
  }

  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 180px !important;
  }
}

@media (max-width: 1200px) {
  .patient-treatment-form-container {
    gap: 30px !important;
  }

  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 400px !important;
  }
}

@media (max-width: 1026px) {
  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 280px !important;
  }
}

@media (max-width: 800px) {
  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 250px !important;
  }
}

@media (max-width: 700px) {
  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 200px !important;
  }
}

@media (max-width: 626px) {
  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 180px !important;
  }
}

@media (max-width: 600px) {
  .patient-treatment-form-container {
    flex-direction: column;
  }

  .admission-time-input,
  .admission-route-input,
  .icu-admission-time-input,
  .discharge-time-input,
  .post-discharge-condition-input,
  .icu-discharge-time-input,
  .icu-post-discharge-condition-input {
    width: 100% !important;
  }
}
