@import "src/constants/common/_variables.module.scss";

.suspicion-btn,
.pmh-btn {
  box-shadow: none;
  font-size: 12px;
  font-weight: 500;
}

.suspicion-btn {
  padding-left: 0;
  padding-right: 0;
}

.suspicion-btn-inactive:hover {
  background-color: $primary-2 !important;
  color: $light !important;
  opacity: 0.7 !important;
}

.pmh-btn-inactive:hover {
  background-color: $primary-2 !important;
  color: $light !important;
  opacity: 0.7 !important;
}

.patient-detail-problem-input {
  padding: 11px 14px;
  resize: none !important;
  padding-bottom: 0;

  &.ant-input-outlined:hover,
  .ant-input-outlined:focus {
    background-color: $background-light;
  }

  &.ant-input-outlined:focus {
    box-shadow: 0 0 0 2px $primary-2;
  }

  &::placeholder {
    font-weight: 500;
    color: $body;
  }
}

@media (max-width: 1391px) {
  .patient-detail-problem-input {
    width: 214px;
  }
}
