@import "src/constants/common/_variables.module.scss";

.examination-result-table {
  width: fit-content;
  table {
    border-radius: 6px;
  }

  .ant-table {
    overflow: auto;
    scrollbar-color: inherit;
  }

  th {
    background-color: $primary-3 !important;

    div:not(.examination-result-table-first-col-header) {
      font-size: 14px;
      font-weight: 500;
      color: $grey-1;
      text-align: center;
    }
  }

  td {
    height: 85px;
  }
  .exam-result-red-text {
    color: $abp;
    font-weight: 600;
  }

  .exam-result-blue-text {
    color: $spo2;
    font-weight: 600;
  }
}
