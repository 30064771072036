@import "src/constants/common/_variables.module.scss";

.patient-detail-personal-info {
  min-width: 387px;
}

.patient-detail-examination-btn,
.patient-detail-ventilator-btn,
.patient-detail-list-btn {
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;
}

.patient-detail-examination-btn {
  .ant-btn-icon {
    transform: translateY(4px);
  }

  span:last-child {
    transform: translateX(3px);
  }
}

.patient-detail-ventilator-btn {
  .ant-btn-icon {
    transform: translate(-30px, 3px);
  }

  span:last-child {
    transform: translate(-27px, -1px);
  }
}

.patient-detail-list-btn {
  text-align: start;
  margin-left: 10px;

  .ant-btn-icon {
    transform: translate(1px, 3px);
  }

  span:last-child {
    transform: translate(1px, -1px);
  }

  &:hover {
    background-color: $primary-3 !important;
    color: $primary-2 !important;

    opacity: 0.7 !important;
  }
}
