@import "src/constants/common/_variables.module.scss";

.noteSection {
  flex: 1;
  min-height: 774px;
}

.groupTabs {
  width: 63px;
  position: relative;
}

.admissionTab,
.detailTab,
.progressNoteTab {
  font-family: "Manrope", "Poppins", sans-serif;
  background-color: $grey-6;
  font-size: 20px;
  font-weight: 500;
  width: 256px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  border: none;
  transform: rotate(-90deg);
  transform-origin: 0 0;

  position: absolute;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: $primary-3;
    color: $primary-1;
    font-weight: 500;
  }
}

.admissionTab {
  bottom: 456px;
}

.detailTab {
  bottom: 196px;
}

.progressNoteTab {
  bottom: -64px;
}

.noteDetail {
  flex: 1;
}
