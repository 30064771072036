@import "src/constants/common/_variables.module.scss";

.progressNoteDatePickerContainer {
  width: 161px;
  height: 34px;
  background-color: $primary-3;
  border-radius: 6px;
  padding-left: 8px;
}

.prevDateBtn,
.nextDateBtn {
  transform: translateY(2.5px);

  &:hover {
    cursor: pointer;
  }

  &.notAllowCursor:hover {
    cursor: not-allowed;
  }
}
