@import "src/constants/common/_variables.module.scss";

.time-series-exam-table {
  width: fit-content;
  table {
    border-radius: 6px;
  }

  .ant-table {
    overflow: auto;
    scrollbar-color: inherit;
  }

  .ant-table-container {
    overflow: auto;
  }

  .ant-table-thead {
    height: 72px;
  }

  th {
    background-color: $primary-3 !important;

    div:not(.time-series-exam-table-first-col-header) {
      font-size: 14px;
      font-weight: 500;
      color: $grey-1;
      text-align: center;
    }
  }

  .time-series-exam-table-first-col-header.ant-table-cell-row-hover {
    background-color: $primary-3 !important;
  }

  .ant-table-cell {
    color: $grey-1;
  }

  .ant-table-cell:not(
      .time-series-exam-table-first-col-header
    ).ant-table-cell-row-hover {
    background-color: $grey-8 !important;
  }

  .time-series-exam-header div {
    font-size: 12px !important;
  }

  td {
    height: 85px;
  }

  td.ant-table-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ant-table-row {
    height: 90px;
  }

  .time-series-exam-odd-cell {
    background-color: $grey-8;
  }

  .ant-table-tbody {
    tr:last-child > *:first-child {
      border-bottom-left-radius: 6px;
    }

    tr:last-child > *:last-child {
      border-bottom-right-radius: 6px;
    }
  }

  .exam-result-red-text {
    color: $abp;
    font-weight: 600;
  }

  .exam-result-blue-text {
    color: $spo2;
    font-weight: 600;
  }
}
