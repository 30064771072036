@import "src/constants/common/_variables.module.scss";

.patientTreatmentForm {
  background-color: $light;
  height: 776px;
  overflow: auto;
  padding-top: 104px;
  padding-left: 30px;
  padding-right: 30px;
}

.admissionTimeLabel,
.admissionRouteLabel,
.icuAdmissionTimeLabel,
.weightLabel,
.heightLabel,
.bsaLabel,
.dischargeTimeLabel,
.postDischargeConditionLabel,
.icuDischargeTimeLabel,
.icuPostDischargeConditionLabel {
  margin-bottom: 6px;
  min-height: 20px;
  color: $title;
}

.admissionRouteLabel,
.icuAdmissionTimeLabel,
.weightLabel,
.heightLabel,
.bsaLabel,
.postDischargeConditionLabel,
.icuDischargeTimeLabel,
.icuPostDischargeConditionLabel {
  margin-top: 32px;
}

@media (max-width: 1900px) {
  .patientTreatmentForm {
    max-height: 776px;
    padding-bottom: 104px;
    padding-top: 60px;
  }
}

@media (max-width: 1232px) {
  .patientTreatmentForm {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1200px) {
  .patientTreatmentForm {
    padding-left: 30px;
    padding-right: 30px;
  }
}
