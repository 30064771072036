@import "src/constants/common/_variables.module.scss";

.wrapper {
  background-color: $background-light;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 104px;

  flex: 1;
}
