@import "src/constants/common/_variables.module.scss";

.doctor-tag,
.department-tag {
  font-family: "Manrope", "Poppins", sans-serif;
  color: $grey-2 !important;
  margin-right: 5px;
  border-radius: 6px;
  text-align: center;
}

.visible-hidden {
  visibility: hidden;
}

@media (max-width: 1580px) {
  .doctor-tag,
  .department-tag {
    font-size: 10px;
  }
}
