@import "src/constants/common/_variables.module.scss";

.time-series-exam-personal-info {
  min-width: 555px !important;
}

.time-series-exam-result-btn {
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;

  .ant-btn-icon {
    transform: translate(-1px, 3px);
  }

  span:last-child {
    transform: translate(1px, -1px);
  }
}
