@import "src/constants/common/_variables.module.scss";

.patient-search-modal {
  margin-top: 16px;
  margin-bottom: 16px;

  .ant-modal-header {
    border-bottom: 1px solid $background-secondary;
    margin-bottom: 0;
  }

  .ant-modal-body {
    padding: 29px 30px;
    border-bottom: 1px solid $background-secondary;
  }

  .ant-modal-close {
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-right: 14px;
  }

  .ant-modal-content {
    padding: 0;

    .age-error-message {
      margin-top: 6px;
    }
  }

  .ant-modal-footer {
    margin-top: 0;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }

  .patient-search-reset-btn {
    box-shadow: none;

    &:hover {
      background-color: $grey-7 !important;
      color: $grey-3 !important;
    }
  }

  .start-date-input,
  .end-date-input {
    width: 100%;
  }
}
