@import "src/constants/common/_variables.module.scss";

.header {
  height: 66px;
}

@media (max-width: 480px) {
  .header {
    flex-direction: column;
  }
}
