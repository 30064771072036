@import "src/constants/common/_variables.module.scss";

.icu-number-input {
  .ant-input-number-input {
    padding: 11px 14px;
  }
}

.ant-input-number-input {
  font-family: "Manrope", "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.ant-input-number-input-wrap {
  height: 100%;

  input {
    height: 100%;
  }
}

.ant-input-number-outlined:hover,
.ant-input-number-outlined:focus,
.ant-input-number-outlined:focus-within {
  background-color: $background-light;
}

.ant-input-number-outlined:focus,
.ant-input-number-outlined:focus-within {
  box-shadow: 0 0 0 2px $primary-2;
}
