@import "src/constants/common/_variables.module.scss";

.patient-list-discharge-btn,
.patient-list-readmit-btn,
.patient-list-detail-btn,
.patient-list-edit-btn {
  font-family: "Manrope", "Poppins", sans-serif;
  font-size: 12px;
  box-shadow: none;
}

.patient-list-discharge-btn {
  .ant-btn-icon {
    transform: translate(-2px, 3px);
  }
  span:last-child {
    transform: translate(0, -1px);
  }
  &:hover {
    background-color: $danger !important;
    color: $grey-9 !important;
    opacity: 0.7;
  }

  &:disabled:hover {
    background: rgba(0, 0, 0, 0.04) !important;
    color: rgba(0, 0, 0, 0.25) !important;
    opacity: 1;
  }
}

.patient-list-readmit-btn {
  .ant-btn-icon {
    transform: translate(-4px, 3px);
  }
  span:last-child {
    transform: translate(-2px, -1px);
  }
}

.patient-list-detail-btn {
  .ant-btn-icon {
    transform: translate(-13px, 3px);
  }
  span:last-child {
    transform: translate(-11px, 0);
  }
  &:hover {
    background-color: $success !important;
    color: $grey-9 !important;
    opacity: 0.7;
  }
}

.patient-list-edit-btn {
  .ant-btn-icon {
    transform: translate(-19px, 3px);
  }
  span:last-child {
    transform: translate(-17px, 0);
  }
  &:hover {
    background-color: $primary-2 !important;
    color: $grey-9 !important;
    opacity: 0.7;
  }
}

@media (max-width: 1680px) {
  .patient-list-personal-info {
    gap: 16px !important;
    min-width: 300px;
  }
}

@media (max-width: 1440px) {
  .patient-list-personal-info {
    min-width: 266px;
  }

  .patient-list-discharge-btn,
  .patient-list-readmit-btn,
  .patient-list-detail-btn,
  .patient-list-edit-btn {
    width: 107px !important;
  }
}

@media (max-width: 1400px) {
  .patient-list-personal-info {
    gap: 0px !important;
    min-width: 250px;
  }
}

@media (max-width: 1366px) {
  .patient-list-personal-info {
    min-width: 238px;
  }
}
