@import "src/constants/common/_variables.module.scss";
.inOutBalanceComponent,
.pdf-in-out-balance-component {
    position: relative;
    background-color: $white;
    margin-left: -23px;
    border-radius: 10px;
    max-width: 2361px;
    tr:not(:first-child) td:not(:first-child) {
        padding: 16px 3px !important;
    }
}
.balanceTableSection,
.pdf-balance-table-section {
    .ant-table-container{
        .ant-table-content {
            scrollbar-color: auto;
        }
    }
    .balance-table {
        position: absolute;
        .ant-table-container {
            border-radius: 10px;
        }
        thead {
            display: none;
        }
        tr {
            td:first-child {
                background-color: $danger;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                color: $white;
                font-size: 20px;
                font-weight: 600;
            }
            td:not(:first-child) {
                font-size: 14px;
                color: $grey-1;
                font-weight: 500;
                background-color: $pink;
                border: solid 1px $pink-1;
            }
        }
    }
}

.medicine-dropdown-overlay {
    ul {
        width: 143px;
        height: 143px;
        padding: 30px !important;
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
            padding: 0 !important;
        }
    }
    .medicine-menu-item {
        color: $grey-3;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 10px;
        }
        .in-icon {
            background-image: url('../../../assets/img/in.png');
        }
    
        .out-icon {
            background-image: url('../../../assets/img/out.png');
        }
        .medicine-menu-item-content {
            display: flex;
            align-items: center;
            width: 83px;
            padding-left: 17px;
            height: 34px;
            background: $background-light;
            border-radius: 6px;
        }
        &.active {
            color: $primary-2;
            .in-icon {
                background-image: url('../../../assets/img/in-active.png');
            }
        
            .out-icon {
                background-image: url('../../../assets/img/out-active.png');
            }
            .medicine-menu-item-content {
                background: $primary-3;
            }
        }
    }
}

.inbalance-delete-dropdown-overlay {
    ul {
        width: 146px;
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        li {
            padding: 0 !important;
        }
    }
    .delete-menu-item {
        color: $grey-3;
        font-size: 14px;
        font-weight: 500;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 13px;
        &:first-child {
            border-top-left-radius: 4px ;
            border-top-right-radius: 4px;
        }
        &:last-child {
            border-bottom-left-radius: 4px ;
            border-bottom-right-radius: 4px;
        }
        &.active {
            color: $white;
            background: $primary-2;
        }
    }
}

.medicineAdd {
    position: absolute;
    left: 8px;
    top: 6px;
    z-index: 3;

    .medicine-dropdown-menu {
        width: 81px;
        height: 34px;
        background-color: $primary-3 !important;
        color: $primary-2;
        align-items: center;
        border: none;

        .medicine-add-icon {
            display: inline-flex;
            color: $white;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: $primary-2;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;
            margin-right: 10px;
        }

    }
}