@import "src/constants/common/_variables.module.scss";

.ant-space-item {
  overflow: auto;
}

.bio-chemistry-screen-table,
.blood-count-screen-table,
.coagulation-screen-table,
.other-screen-table,
.blood-gas-screen-table,
.urinary-screen-table {
  th,
  td {
    padding: 14px !important;
  }
}

.bio-chemistry-screen-table,
.blood-count-screen-table,
.coagulation-screen-table,
.blood-gas-screen-table,
.urinary-screen-table {
  th.examination-result-table-first-col-header {
    min-width: 167px;
  }

  th:not(.examination-result-table-first-col-header) {
    min-width: 84px;
  }
}

.bio-chemistry-pdf-table,
.blood-count-pdf-table,
.coagulation-pdf-table,
.blood-gas-pdf-table,
.urinary-pdf-table {
  display: none;
}

.blood-gas-icon {
  transform: translateX(16px);
}

.examination-page-pdf {
  .exam-card-patient-info {
    flex: 1;
    border: 1px solid #000000 !important;
  }

  .examination-result-personal-info-container {
    margin-top: -16px;
    margin-bottom: 4px !important;
    border: 1px solid #000000 !important;
  }

  .icu-header,
  .examination-result-time-series-btn,
  .pdf-export-btn,
  .icu-custom-date-picker,
  .header-user-info,
  .exam-card-group-btn,
  .icu-footer,
  .bio-chemistry-screen-table,
  .blood-count-screen-table,
  .coagulation-screen-table,
  .other-screen-table,
  .blood-gas-screen-table,
  .urinary-screen-table {
    display: none;
  }

  .bio-chemistry-pdf-table,
  .blood-count-pdf-table,
  .coagulation-pdf-table,
  .other-pdf-table,
  .blood-gas-pdf-table,
  .urinary-pdf-table {
    display: table;

    margin-top: 4px;

    th,
    td {
      padding: 4px !important;
    }

    th.examination-result-table-first-col-header {
      min-width: 167px;
    }

    th:not(.examination-result-table-first-col-header) {
      min-width: 90px;
      max-width: 90px;
      width: 90px;
    }

    .ant-table-cell,
    .ant-table-cell div,
    .ant-table-cell span,
    .examination-result-table-first-col-header {
      font-size: 10px !important;
    }

    td {
      height: 10px !important;
    }

    .blood-gas-icon {
      transform: translateX(0) !important;
    }

    .ant-table-cell,
    table > thead > tr:not(:last-child) > th {
      border: 1px solid #000000 !important;
    }
  }

  .icu-header {
    position: relative;
  }

  .icu-wrapper {
    background-color: $light;
    margin-top: 32px;

    padding-left: 32px;
    padding-right: 32px;
  }
}

@media print {
  @page {
    margin-top: 32px;
  }

  @page :first {
    margin-top: 0px;
  }
}
