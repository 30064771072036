@import "src/constants/common/_variables.module.scss";

.icu-radio {
  span.ant-radio-inner {
    background-color: $grey-7;
    border: none;

    width: 22px;
    height: 22px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.75);
  }

  span:last-child {
    font-family: "Manrope", "Poppins", sans-serif;
    font-weight: 400;

    color: $grey-3;
  }
}
