@import "src/constants/common/_variables.module.scss";

.ventilator-table,
.pdf-ventilator-table {
  position: absolute;
  left: 305px;
  max-width: calc(100% - 305px);

  .ant-table {
    scrollbar-color: inherit;
  }

  .ventilator-table-first-col {
    border-bottom: none !important;

    div {
      font-weight: 600;
    }
  }

  th {
    background-color: $primary-3 !important;

    div {
      font-size: 14px;
      font-weight: 500;
      color: $grey-1;
      text-align: center;
    }
  }

  td {
    color: $grey-1;
  }

  .ant-table-cell {
    padding: 11.5px 16px !important;
  }

  .ant-table-cell:has(.ventilator-title-cell) {
    background-color: $rr;
  }
}