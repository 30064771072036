@import "src/constants/common/_variables.module.scss";

.patientItem {
  min-height: 96px;
  border-radius: 6px;
  border: 1px solid $primary-3;
  overflow: auto;
  margin-bottom: 26px;
}

.orderNumber {
  min-width: 72px;
  background-color: $primary-3;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.groupBtn {
  flex: 1;
  background-color: $light;
  min-width: 482px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 30px;
}
