@import "src/constants/common/_variables.module.scss";

@keyframes ldio-ax86xcloi0p {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-ax86xcloi0p div {
  box-sizing: border-box !important;
}
.ldio-ax86xcloi0p > div {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: $primary-2 transparent $primary-2 transparent;
  animation: ldio-ax86xcloi0p 1.1111111111111112s linear infinite;
}
.ldio-ax86xcloi0p > div:nth-child(2) {
  border-color: transparent;
}
.ldio-ax86xcloi0p > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-ax86xcloi0p > div:nth-child(2) div:before,
.ldio-ax86xcloi0p > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 28px;
  background: $primary-2;
  border-radius: 50%;
  box-shadow: 0 72px 0 0 $primary-2;
}
.ldio-ax86xcloi0p > div:nth-child(2) div:after {
  left: -8px;
  top: 28px;
  box-shadow: 72px 0 0 0 $primary-2;
}
.loadingio-spinner-dual-ring-3hlymsf6nfi {
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
}
.ldio-ax86xcloi0p {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
