@import "src/constants/common/_variables.module.scss";

.icu-date-input {
  padding: 11px 14px;

  .ant-picker-input input,
  .ant-picker-input input::placeholder {
    font-family: "Manrope", "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: $grey-2;
  }

  &.ant-picker-outlined:hover {
    background-color: $background-light !important;
  }

  &.ant-picker-disabled:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  &.ant-picker-outlined:focus-within {
    background-color: $background-light;
    box-shadow: 0 0 0 2px $primary-2;
  }
}
