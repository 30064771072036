@import "src/constants/common/_variables.module.scss";

.personalInfo {
  height: 100%;
  border-right: 1px solid $primary-3;
  padding-left: 16px;
  padding-right: 16px;
  background-color: $grey-9;
  min-width: 314px;
}

.icon {
  margin-right: 10px;
}

.label {
  margin-right: 2px;
}
