@import "src/constants/common/_variables.module.scss";

.problemItem {
  min-height: 138px;
  border: 1px solid $primary-3;
  border-radius: 6px;
  width: 574px;
  margin-bottom: 8px;
}

.orderNumber {
  min-width: 53px;
  background-color: $primary-3;
  color: $grey-2;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainContent {
  padding: 16px;
  flex: 1;
}

.groupBtn {
  margin-right: 16px;
}

.problemInput {
  width: 245px;
  min-height: 106px !important;
  background-color: $background-light;
  border: none;
  margin-right: 16px;
}

.closeIcon {
  font-size: 10px;
  color: $grey-3;
  transform: translate(-4px, -1px);
}

@media (max-width: 710px) {
  .problemItem {
    max-width: 576px;
    overflow: auto;
  }
}
