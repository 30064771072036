@import "src/constants/common/_variables.module.scss";

.patient-edit-modal {
  .ant-modal-header {
    border-bottom: 1px solid $background-secondary;
    margin-bottom: 0;
  }

  .ant-modal-body {
    padding: 29px 30px;
  }

  .ant-modal-close {
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-right: 14px;
  }

  .ant-modal-content {
    padding: 0;

    .age-error-message {
      margin-top: 6px;
    }
  }

  .patient-edit-reset-btn {
    box-shadow: none;

    &:not(:disabled):hover {
      background-color: $grey-7 !important;
      color: $grey-3 !important;
    }
  }

  .patient-edit-divider {
    height: 465px;
    border-color: $primary-3;
  }

  .ant-modal-footer {
    display: none;
  }

  .patient-edit-error-msg {
    margin-top: 6px;
  }
}
