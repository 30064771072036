@import "src/constants/common/_variables.module.scss";

.errorTitle {
  font-family: "Manrope", "Poppins", sans-serif;
  color: $title;
  margin-left: 6px;
}

.errorContent {
  font-family: "Manrope", "Poppins", sans-serif;
  font-size: 20px;
  color: $abp;
  text-align: center;
  margin-top: 49px;
  margin-bottom: 60px;
}
