@import "src/constants/common/_variables.module.scss";

.login-error-msg {
  margin-top: 6px;
}

.login-btn {
  .ant-btn {
    font-family: "Roboto", "Poppins", sans-serif;
  }
}

.login-password-input {
  width: 500px;
  height: 42px;

  &.ant-input {
    padding: 11px 14px;
    font-family: "Manrope", "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-input-password-icon {
    font-size: 16px;
  }
}

.ant-input-outlined:hover,
.ant-input-outlined:focus {
  background-color: $background-light !important;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  box-shadow: 0 0 0 2px $primary-2 !important;
}

@media (max-width: 520px) {
  .login-username-input,
  .login-password-input,
  .login-submit-btn {
    width: 100% !important;
  }

  .login-username-container,
  .login-password-container,
  .login-btn {
    width: 100%;
    padding-right: 18px;
    padding-left: 18px;
  }
}

@media (max-width: 520px) {
  .login-submit-btn {
    font-size: 12px;
  }
}

@media (max-width: 260px) {
  .login-submit-btn {
    font-size: 10px;
  }
}
