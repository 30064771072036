@import "src/constants/common/_variables.module.scss";

.problemListSection {
  min-width: 640px;
  height: 774px;
  background-color: $light;
  border-radius: 6px;
}

.heading {
  padding-left: 30px;
  padding-right: 30px;
  height: 66px;
  border-bottom: 1px solid $background-secondary;
  font-size: 16px;
  font-weight: 600;
}

.list {
  padding-left: 24px;
  padding-right: 10px;
  margin-top: 30px;
  max-height: 678px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 24px;
}

@media (max-width: 704px) {
  .problemListSection {
    min-width: 100%;
  }
}

@media (max-width: 1152px) {
  .list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
