@import "src/constants/common/_variables.module.scss";

.patientItem {
  min-height: 138px;
  border-radius: 6px;
  border: 1px solid $primary-3;
  margin-top: 16px;
}

.treatmentInfoSection {
  flex: 1;
  min-width: 788px;
}

.orderNumber {
  display: flex;
  min-width: 72px;
  background-color: $primary-3;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.admittedGroupBtn,
.dischargedGroupBtn {
  flex: 1;
  padding-right: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: $light;
  min-width: 177px;
  max-width: 177px;
}

@media (max-width: 1680px) {
  .orderNumber {
    min-width: 52px;
  }

  .patientItem {
    font-size: 12px;
  }

  .admittedGroupBtn,
  .dischargedGroupBtn {
    padding-right: 14px;
    min-width: 148px;
    max-width: 148px;
  }
}

@media (max-width: 1580px) {
  .orderNumber {
    min-width: 40px;
  }

  .patientItem {
    font-size: 11px;
  }

  .treatmentInfoSection {
    min-width: 680px;
  }
}

@media (max-width: 1440px) {
  .admittedGroupBtn,
  .dischargedGroupBtn {
    padding-right: 14px;
    min-width: 130px;
    max-width: 130px;
  }
}

@media (max-width: 1280px) {
  .patientItem {
    max-width: 1248px;
    overflow: auto;
    font-size: 9px;
  }

  .treatmentInfoSection {
    min-width: 682px;
  }
}
