@import "src/constants/common/_variables.module.scss";

.loginForm {
  width: 50%;
}

.logo {
  margin-top: 36px;
  width: 500px;
}

.headingSection {
  width: 500px;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 30px;

  margin-top: 215px;
  margin-bottom: 4px;
}

.subHeading {
  color: $grey;
  font-size: 16px;

  margin-bottom: 60px;
}

.userNameLabel,
.passwordLabel {
  margin-bottom: 6px;
  color: $title;
}

.passwordLabel,
.loginBtn {
  margin-top: 28px;
}

.userNameInput,
.passwordInput {
  margin-bottom: 28px;
}

@media (max-width: 1440px) {
  .heading {
    margin-top: 168px;
  }
}

@media (max-width: 1300px) {
  .loginForm {
    flex: 1;
  }

  .headingSection,
  .formSection {
    display: flex;
    flex-direction: column;
  }

  .heading {
    margin-top: 68px;
  }
}

@media (max-width: 1024px) {
  .formSection,
  .headingSection {
    width: 100%;
    align-items: center;
  }

  .logo {
    width: 100%;
    text-align: center;
  }

  .heading {
    margin-top: 140px;
  }
}

@media (max-width: 520px) {
  .logo img {
    width: 156px;
  }

  .heading {
    font-size: 20px;
    margin-top: 120px;
  }

  .subHeading {
    font-size: 14px;
  }

  .userNameLabel,
  .passwordLabel {
    font-size: 12px;
  }
}

@media (max-width: 260px) {
  .heading {
    font-size: 16px;
    margin-top: 100px;
  }

  .subHeading {
    font-size: 12px;
  }

  .userNameLabel,
  .passwordLabel {
    font-size: 10px;
  }
}
