@import "src/constants/common/_variables.module.scss";

.vital-sign-check-input {
  &:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $grey-9 !important;
    border-color: $primary-2 !important;
  }

  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
  }

  .ant-checkbox-inner:after {
    top: 50%;
    inset-inline-start: 50%;
    width: 14px;
    height: 14px;
    background-color: $grey-4;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: "";
    border-radius: 2px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $grey-9;
    border-color: #d9d9d9;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    top: 50%;
    inset-inline-start: 50%;
    width: 14px;
    height: 14px;
    background-color: $primary-2;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: "";
    border-radius: 2px;
  }

  .ant-checkbox + span {
    padding-inline-end: 0;
  }
}
