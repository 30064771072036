@import "src/constants/common/_variables.module.scss";

.filteredTab {
  font-family: "Manrope", "Poppins", sans-serif;
  background-color: $grey-6;
  font-size: 20px;
  font-weight: 600;
  width: 50%;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.active {
  background-color: $primary-3;
  color: $primary-2;
}
