@import "src/constants/common/_variables.module.scss";

.add-problem-btn {
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;

  .ant-btn-icon {
    font-size: 16px;
    transform: translate(-1px, 2px);
  }

  span:last-child:not(.anticon) {
    transform: translateX(1px);
  }
}

.problem-item-delete-btn {
  box-shadow: none;

  &:hover {
    background-color: $background-secondary !important;
    opacity: 0.7;
  }
}
