@import "src/constants/common/_variables.module.scss";

.patientDetailPage {
  padding-top: 28px;
  padding-bottom: 31px;
}
@media (max-width: 1200px) {
  .mainContent {
    flex-direction: column;
  }
}
